import React from "react";
import { Grid, Container, Card, CardContent, Typography } from "@mui/material";
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";

function Pages() {
  const audioData = [
    { audioSrc: "audio/Alice_English.mp3", title: "English Sample" },
    { audioSrc: "audio/Alice_Spanish.mp3", title: "Spanish Sample" },
    { audioSrc: "audio/Alice_German.mp3", title: "German Sample" },
    { audioSrc: "audio/Alice_Chinese.mp3", title: "Chinese Sample" },
  ];

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Card sx={{ width: "100%", maxWidth: "450px", p: 3, textAlign: "center" }}>
            <Grid
              container
              item
              flexDirection="column"
              alignItems="center"
              sx={{ textAlign: "center", my: 0, mx: "auto", px: 0.75 }}
            >
              <MKBadge
                variant="contained"
                color="info"
                badgeContent="Audio Demos"
                container
                sx={{ mb: 2 }}
              />
            </Grid>
            <CardContent>
              {audioData.map(({ audioSrc, title }) => (
                <div key={title} style={{ marginBottom: "30px" }}>
                  <Typography variant="h6" sx={{ mb: 0.5 }}>
                    {title}
                  </Typography>
                  <audio controls style={{ width: "90%" }}>
                    <source src={audioSrc} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
